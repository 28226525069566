// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_sk d_fn d_bF d_dt";
export var alignLeft = "t_p9 d_fn d_bF d_dt";
export var alignDiscCenter = "t_sl d_fp d_bC d_dv";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignDiscRight = "t_sm d_fq d_bG d_dw";
export var alignRight = "t_qb d_fq d_bG d_dw";
export var footerContainer = "t_sn d_dV d_bV";
export var footerContainerFull = "t_sp d_dS d_bV";
export var footerHeader = "t_kd d_kd";
export var footerTextWrapper = "t_sq d_v";
export var footerDisclaimerWrapper = "t_kl d_kl d_cj";
export var badgeWrapper = "t_sr d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "t_ss undefined";
export var footerDisclaimerLeft = "t_st undefined";
export var verticalTop = "t_sv d_by d_bH d_bM d_bK";
export var firstWide = "t_sw";
export var disclaimer = "t_sx d_by d_bH";
export var socialDisclaimer = "t_sy";
export var left = "t_sz";
export var wide = "t_sB d_cx";
export var right = "t_sC d_bJ";
export var line = "t_fj d_fk d_ct";
export var badgeDisclaimer = "t_sD d_bB d_bN d_bH";
export var badgeContainer = "t_sF d_by d_bG d_bN";
export var badge = "t_sG";
export var padding = "t_sH d_c6";
export var end = "t_sJ d_bG";
export var linkWrapper = "t_sK d_dz";
export var link = "t_mB d_dz";
export var colWrapper = "t_sL d_cw";
export var consent = "t_f d_f d_bB d_bN";
export var media = "t_sM d_bw d_dx";
export var itemRight = "t_sN";
export var itemLeft = "t_sP";
export var itemCenter = "t_sQ";
export var exceptionWeight = "t_sR C_t7";