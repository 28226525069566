// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "x_th d_gR d_cv d_dt";
export var quoteParagraphCenter = "x_gS d_gS d_cv d_dv";
export var quoteParagraphRight = "x_gT d_gT d_cv d_dw";
export var quoteRowLeft = "x_tj d_bF";
export var quoteRowCenter = "x_tk d_bC";
export var quoteRowRight = "x_tl d_bG";
export var quoteWrapper = "x_gP d_gP d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "x_gQ d_gQ";
export var quoteExceptionSmall = "x_tm C_tm";
export var quoteExceptionNormal = "x_tn C_tn";
export var quoteExceptionLarge = "x_tp C_tp";
export var quoteAuthorExceptionSmall = "x_tq C_tq";
export var quoteAuthorExceptionNormal = "x_tr C_tr";
export var quoteAuthorExceptionLarge = "x_ts C_ts";