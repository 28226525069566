// extracted by mini-css-extract-plugin
export var articleWrapper = "l_n1";
export var searchResult = "l_pK l_n1";
export var line = "l_fj d_fj d_cX";
export var articleTitle = "l_pL d_cp";
export var articleText = "l_n2 d_cr";
export var authorImage = "l_ln d_ln d_b6 d_Y d_cs";
export var authorText = "l_pt d_cg d_cs";
export var textCenter = "l_dv d_dv";
export var searchWrapper = "l_pM d_by d_bN d_dq d_ck d_Y";
export var searchInput = "l_pN d_cD d_v d_b3";
export var searchBtn = "l_pP d_b0 d_bB d_bN d_Z d_cC d_dz";
export var text = "l_pF d_cq";
export var elementWrapper = "l_mw d_ck";
export var select = "l_pQ d_v d_b3";
export var articleImageWrapper = "l_pk d_cr d_Y d_T";
export var articleImageWrapperIcon = "l_pl d_cr d_Y d_T";
export var articleImageWrapperColumns = "l_pR d_cr d_fg d_Y";
export var contentPadding = "l_pS d_cR";
export var otherPadding = "l_pT d_cL";
export var paginationWrapper = "l_pV";
export var pagination = "l_pW";
export var disabledPagination = "l_pX";
export var breakMe = "l_pY";
export var activePage = "l_pZ";
export var next = "l_p0";
export var pages = "l_p1";
export var searchTitle = "l_p2 d_cq";
export var categoryTitle = "l_p3 d_cq d_Y";
export var searchText = "l_p4 d_cs";
export var bold = "l_p5";
export var field = "l_p6";
export var dateTag = "l_pb d_k d_cq d_dp";
export var icon1 = "l_pc d_cN";
export var icon2 = "l_pd d_cN d_cH";
export var tagBtn = "l_pf d_b0 d_cC d_dn d_dz";
export var linksWrapper = "l_px d_cg d_dv";
export var header = "l_n3 d_v d_Y";
export var headerImage = "l_p7 d_bc d_Z d_7 d_6 d_3 d_8";
export var separator = "l_ps d_cX";
export var resultImage = "l_p8";