// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sS d_gt d_cr";
export var heroHeaderCenter = "v_gv d_gv d_cr d_dv";
export var heroHeaderRight = "v_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "v_sT d_gq d_cv";
export var heroParagraphCenter = "v_gr d_gr d_cv d_dv";
export var heroParagraphRight = "v_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "v_sV d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "v_sW d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "v_sX d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "v_sY d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "v_sZ d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "v_s0 C_s0";
export var heroExceptionNormal = "v_s1 C_s1";
export var heroExceptionLarge = "v_s2 C_s2";
export var Title1Small = "v_s3 C_s3 C_tH C_tJ";
export var Title1Normal = "v_s4 C_s4 C_tH C_tK";
export var Title1Large = "v_s5 C_s5 C_tH C_tL";
export var BodySmall = "v_s6 C_s6 C_tH C_t0";
export var BodyNormal = "v_s7 C_s7 C_tH C_t1";
export var BodyLarge = "v_s8 C_s8 C_tH C_t2";