// extracted by mini-css-extract-plugin
export var lbContainerOuter = "F_v4";
export var lbContainerInner = "F_v5";
export var movingForwards = "F_v6";
export var movingForwardsOther = "F_v7";
export var movingBackwards = "F_v8";
export var movingBackwardsOther = "F_v9";
export var lbImage = "F_wb";
export var lbOtherImage = "F_wc";
export var prevButton = "F_wd";
export var nextButton = "F_wf";
export var closing = "F_wg";
export var appear = "F_wh";