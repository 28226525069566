// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "s_rM d_bC";
export var datasheetRowWrapper = "s_lt d_lt d_bJ";
export var datasheetLeftWrapper = "s_rN d_by d_bN";
export var datasheetWrapperFull = "s_rP d_cC";
export var datasheetWrapperFullLeft = "s_nG d_cC d_by d_bN d_bC d_bS";
export var contentWrapper = "s_n9 d_lv";
export var contentWrapperCenter = "s_rQ d_lz";
export var datasheetLeftWrapperCenter = "s_rR d_lx d_by d_bC d_bN d_bH";
export var datasheetImageCenterWrapper = "s_lr d_lr d_v d_ct";
export var datasheetRightWrapperCenter = "s_rS d_lw";
export var datasheetFooter = "s_ly d_ly";
export var alignLeft = "s_p9 d_dt";
export var alignCenter = "s_bN d_dv";
export var alignRight = "s_qb d_dw";
export var datasheetInnerWrapperNewLeft = "s_rT d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetInnerWrapperLeft = "s_rV d_fc d_N d_Y d_by d_bN d_b4 d_dh d_c8";
export var datasheetInnerWrapperRight = "s_rW d_fd d_N d_Y d_by d_bN d_dh";
export var datasheetRowWrapperReverse = "s_rX d_bJ";
export var datasheetSubtitle = "s_rY d_cw";
export var tableRow = "s_rZ";
export var cell = "s_r0";
export var tableRowWrapper = "s_r1 d_v d_dt";
export var tableHeadingCell = "s_r2";
export var tableHeading = "s_r3";
export var tableRowStriped = "s_r4";
export var tableRowHeading = "s_r5";
export var dataTable = "s_r6 d_v";
export var imageWrapper = "s_r7 d_ff d_Y";
export var imageWrapperFull = "s_r8 d_G d_v d_bd d_Y";
export var imageWrapperIcon = "s_r9";
export var titleMargin = "s_sb d_cr";
export var datasheetInnerInnerWrapper = "s_sc d_v";
export var hasLabels = "s_sd";
export var label = "s_sf";
export var SmallSmall = "s_sg C_sg C_tH C_t3";
export var SmallNormal = "s_sh C_sh C_tH C_t4";
export var SmallLarge = "s_sj C_sj C_tH C_t1";