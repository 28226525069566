// extracted by mini-css-extract-plugin
export var customText = "r_rF d_dt d_cr d_cf";
export var videoIframeStyle = "r_pj d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "r_rG d_cr d_cf d_Y";
export var customRow = "r_pD d_bC d_bd";
export var quoteWrapper = "r_gP d_bB d_bN d_cr d_cf";
export var quoteBar = "r_pn d_G";
export var masonryImageWrapper = "r_pv";
export var title = "r_rH";
export var Title3Small = "r_rJ C_rJ C_tH C_tQ";
export var Title3Normal = "r_rK C_rK C_tH C_tR";
export var Title3Large = "r_rL C_rL C_tH C_tS";