// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "y_tt d_bC";
export var storyRowWrapper = "y_hw d_hw d_bJ";
export var storyLeftWrapper = "y_tv d_by d_bN";
export var storyWrapperFull = "y_tw d_cC";
export var storyWrapperFullLeft = "y_nM d_cC d_by d_bN d_bC";
export var contentWrapper = "y_n9 d_hx";
export var storyLeftWrapperCenter = "y_tx d_hy d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "y_ty d_hD";
export var storyHeader = "y_tz d_hC d_v d_cr";
export var storyHeaderCenter = "y_hB d_hB d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "y_hz d_hz d_bx d_dv";
export var storyBtnWrapper = "y_tB d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "y_hH d_hH d_d0 d_v d_by d_bC";
export var imageWrapper = "y_r7 d_ff d_Y";
export var imageWrapperFull = "y_r8 d_v d_G d_bd d_Y";