// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "D_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "D_fQ d_fQ d_by d_bH";
export var menuDesign6 = "D_vB d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "D_vC d_fL d_by d_dv d_bN d_bH";
export var menuRight = "D_vD d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "D_vF d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "D_vG d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "D_qw d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "D_qH d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "D_vH";
export var navbarItem = "D_qx d_bw";
export var navbarLogoItemWrapper = "D_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "D_vJ d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "D_vK d_gc d_bx d_Y d_br";
export var burgerToggle = "D_vL d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "D_vM d_gc d_bx d_Y d_br";
export var burgerInput = "D_vN d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "D_vP d_f2 d_v d_G";
export var burgerLine = "D_vQ d_f0";
export var burgerMenuDesign6 = "D_vR d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "D_vS d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "D_vT d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "D_vV d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "D_vW d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "D_vX d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "D_vY d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "D_vZ d_bB d_bN";
export var staticBurger = "D_v0";
export var menu = "D_v1";
export var navbarDividedLogo = "D_v2";
export var nav = "D_v3";